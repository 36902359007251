@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Courier+Prime:wght@400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-size: 1.5rem;
}

@media screen and (min-width: 601px) and (max-width: 1124px) {

  html,
  body {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 600px) {

  html,
  body {
    font-size: .8rem;
  }
}

@mixin animate-nth-child($num, $pos, $duration, $delay: 0) {
  @keyframes remove-cards {
    from {
      transform: #{$pos};
    }

    to {
      transform: translateY(1000px);
    }
  }

  @keyframes deal-#{$num} {
    from {
      transform:
        translateY(-1000px);
    }

    to {
      transform: #{$pos};
    }
  }

  &:nth-child(#{$num}) {
    animation: remove-cards,
    deal-#{$num};
    animation-duration: .1s, $duration;
    animation-delay: 0s, $delay;
    animation-fill-mode: both;
  }
}

.card:first-child:nth-last-child(5),
.card:first-child:nth-last-child(5)~.card {
  @include animate-nth-child(1, 'translate(0, 0) rotate(0)', .5s);
  @include animate-nth-child(2, 'translate(0, 0) rotate(0)', .5s, .2s);
  @include animate-nth-child(3, 'translate(0, 0) rotate(0)', .5s, .4s);
  @include animate-nth-child(4, 'translate(0, 0) rotate(0)', .5s, .6s);
  @include animate-nth-child(5, 'translate(0, 0) rotate(0)', .5s, .8s);

  @media screen and (min-width: 1124px) {
    @include animate-nth-child(1, 'translate(-60px, -80px) rotate(20deg)', .5s);
    @include animate-nth-child(2, 'translate(-30px, -20px) rotate(10deg)', .5s, .2s);
    @include animate-nth-child(3, 'translate(0, 0) rotate(0)', .5s, .4s);
    @include animate-nth-child(4, 'translate(30px, -20px) rotate(-10deg)', .5s, .6s);
    @include animate-nth-child(5, 'translate(60px, -80px) rotate(-20deg)', .5s, .8s);
  }
}

.card:first-child:nth-last-child(2),
.card:first-child:nth-last-child(2)~.card {
  @include animate-nth-child(3, 'translate(0, 0) rotate(0)', .5s);
  @include animate-nth-child(3, 'translate(0, 0) rotate(0)', .5s, .2s);
}

@keyframes scaleIn {
  from {
    scale: 0;
  }

  to {
    scale: 1;
  }
}

.winner-animation {
  animation: scaleIn .3s ease-in-out;
}